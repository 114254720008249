import React, {useContext, useEffect, useState} from "react";
import {useProductsContext} from "./productsContext";
import {useDistributorsContext} from "./distributorsContext";
import {useSKUOfferingStatusesContext} from "./skuOfferingStatusesContext";
import {useSKUFulfillmentTypesContext} from "./skuFulfillmentTypesContext";

const url = `/api/fulfillmentstatus`;

export const FulfillmentStatusContext = React.createContext(null);

export function FulfillmentStatusContextProvider(props) {
    const {selectedProducts} = useProductsContext();
    const {selectedAvailability} = useProductsContext();
    const {selectedSKUOfferingStatuses} = useSKUOfferingStatusesContext();
    const {selectedSKUFulfillmentTypes} = useSKUFulfillmentTypesContext();
    const {selectedDistributors} = useDistributorsContext();
    const [fulfillmentStatusItems, setFulfillmentStatusItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState("");

    function get() {
        const controller = new AbortController();
        const signal = controller.signal;
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                products: selectedProducts,
                availability: selectedAvailability,
                skuOfferingStatuses: selectedSKUOfferingStatuses,
                skuFulfillmentTypes: selectedSKUFulfillmentTypes,
                distributors: selectedDistributors
            }),
            signal: signal,
        };

        setFulfillmentStatusItems([]);

        if ((selectedProducts && selectedProducts.length > 0) || (selectedDistributors && selectedDistributors.length > 0)) {
            setIsLoading(true);

            fetch(`${url}/`, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    setIsLoading(false);
                    setFulfillmentStatusItems(sortData(data))
                    setIsReady(!error);
                })
        }
    }

    const sortData = (data) => {
        return data.sort((a, b) => a.distributor?.division.localeCompare(b.distributor?.division)
            || a.distributor?.region.localeCompare(b.distributor?.region)
            || a.distributor?.sapId.localeCompare(b.distributor?.sapId)
            || a.product?.description.localeCompare(b.product?.description))
    }

    function exportCsv() {
        const controller = new AbortController();
        const signal = controller.signal;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                products: selectedProducts,
                availability: selectedAvailability,
                skuOfferingStatuses: selectedSKUOfferingStatuses,
                skuFulfillmentTypes: selectedSKUFulfillmentTypes,
                distributors: selectedDistributors
            }),
            signal: signal,
        };

        setIsExporting(true);

        fetch(`${url}/csvFile/`, requestOptions)
            .then((response) => {
                const filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
                response.blob()
                    .then((blob) => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                            new Blob([blob]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            filename,
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);

                        setIsExporting(false);
                    });
            });   
    }

    useEffect(() => {
        setIsReady(false);
    }, [selectedProducts, selectedAvailability, selectedSKUOfferingStatuses, selectedSKUFulfillmentTypes, selectedDistributors]);

    return (<FulfillmentStatusContext.Provider
        value={{
            selectedProducts,
            selectedAvailability,
            selectedSKUOfferingStatuses,
            selectedSKUFulfillmentTypes,
            selectedDistributors,
            fulfillmentStatusItems,
            isLoading,
            isReady,
            error,
            get,
            exportCsv,
            isExporting,
        }}
    >
        {props.children}
    </FulfillmentStatusContext.Provider>);
}

export function useFulfillmentStatusContext() {
    const context = useContext(FulfillmentStatusContext);

    if (!context) throw new Error("useFulfillmentStatusContext must be used within a FulfillmentStatusContextProvider. Wrap a parent component in <FulfillmentStatusContextProvider> to fix this error.");
    return context;
}