import { usePlanningWindowContext } from "../Contexts/planningWindowsContext";
import { Link, Stack } from "@mui/material";
import dayjs from "dayjs";
import arrowImage from "../Images/OBO1/next.gif";
import React from "react";
import { OboLink } from "../Styles/Styles";
import { GetOboLink } from "../Utilities/NavigationMenuLinks";
import { useUserDataContext } from "../Contexts/userdataContext";
import { isDistributor } from "../Utilities/UserDataFunctions";
import { DATE_FORMAT } from "../Utilities/utilities";

export default function PlanningWindow() {
    const { userData } = useUserDataContext();
    const oboLink = isDistributor(userData) ? GetOboLink("PlanningWindowDistributor") : GetOboLink("PlanningWindowCoworker");
    const { planningWindows, isReady: arePlanningWindowsReady } = usePlanningWindowContext()

    const areTherePlanningWindows = () => {
        return arePlanningWindowsReady && planningWindows && planningWindows.length > 0
    };

    return (areTherePlanningWindows() ? <>
        {planningWindows.map((planningWindow, index) => (<Stack direction="row" alignItems="center" style={{ height: "21px" }} spacing={.25} key={index}>
            <img src={arrowImage} alt="Arrow" style={{ width: "5px", height: "9px" }} />
            <Link href={`${oboLink.path}${planningWindow.id}`}
                style={OboLink}>({dayjs(planningWindow.start).format(DATE_FORMAT)} - {dayjs(planningWindow.end).format(DATE_FORMAT)}) {planningWindow.description}</Link>
        </Stack>))}
    </> : null);
}